export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=5"},{"name":"theme-color","content":"#0e0e0e"},{"name":"apple-mobile-web-app-capable","content":"yes"},{"name":"apple-mobile-web-app-status-bar-style","content":"white"},{"name":"apple-mobile-web-app-title","content":"allrecipes.club"},{"name":"format-detection","content":"telephone=no"},{"name":"octoclick-verification","content":"129a1800ba60d9e5150b454e064efbf2"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16.png"},{"rel":"icon","type":"image/png","sizes":"192x192","href":"/android-chrome-192x192.png"},{"rel":"icon","type":"image/png","sizes":"512x512","href":"/android-chrome-512x512.png"}],"style":[],"script":[{"tagPosition":"bodyClose","innerHTML":"setTimeout(() => {\n                        const script = document.createElement('script');\n                        script.src = \"//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4873470855788285\";\n                        script.async = true;\n                        script.crossOrigin = \"anonymous\";\n                        script.id = \"script-adsbygoogle\";\n                        document.body.appendChild(script);\n                    }, 3000);"}],"noscript":[],"htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const devPagesDir = null

export const devRootDir = null