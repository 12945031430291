import plugin_vue3_A0OWXRrUgq from "/opt/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import revive_payload_client_4sVQNw7RlN from "/opt/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/opt/app/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/opt/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/opt/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/opt/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_UciE0i6zes from "/opt/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import axios_plugin_6KpEz1milZ from "/opt/app/plugins/axios.plugin.ts";
import imgproxy_plugin_hrINfsxWR6 from "/opt/app/plugins/imgproxy.plugin.ts";
import marked_plugin_QBdEk0Hlmp from "/opt/app/plugins/marked.plugin.ts";
import pinia_plugin_a1SZvVGaTI from "/opt/app/plugins/pinia.plugin.ts";
import vue_gtag_plugin_client_4lUZEDptxK from "/opt/app/plugins/vue-gtag.plugin.client.ts";
export default [
  plugin_vue3_A0OWXRrUgq,
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  chunk_reload_client_UciE0i6zes,
  axios_plugin_6KpEz1milZ,
  imgproxy_plugin_hrINfsxWR6,
  marked_plugin_QBdEk0Hlmp,
  pinia_plugin_a1SZvVGaTI,
  vue_gtag_plugin_client_4lUZEDptxK
]