import { Marked } from "marked";

export type AdditionalType = {
    desktopImageWidth?: number;
    desktopImageHeight?: number;
};

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    nuxtApp.marked = (isArticle: boolean = false, additional?: AdditionalType) => {
        const instance = new Marked();
        instance.setOptions({
            headerIds: false,
            mangle: false,
            breaks: true,
        });
        instance.use({
            renderer: {
                image(href: string = '', title: string = '', text: string = '') {
                    const src = config.public.ssrMediaBaseUrl + href;

                    if (isArticle && additional?.desktopImageWidth) {
                        return `<a href="${nuxtApp.imgProxyResize(src, 700)}" data-fancybox><picture>
<source media="(max-width: 766px)" type="image/webp" srcset="${nuxtApp.imgProxyResize(src, Math.round(additional?.desktopImageWidth / 2), undefined, 'webp')} 1x, ${nuxtApp.imgProxyResize(src, Math.round(additional?.desktopImageWidth / 1.3), undefined, 'webp')} 2x">
<source media="(max-width: 766px)" srcset="${nuxtApp.imgProxyResize(src, Math.round(additional?.desktopImageWidth / 2))} 1x, ${nuxtApp.imgProxyResize(src, Math.round(additional?.desktopImageWidth / 1.3))} 2x">
<source media="(min-width: 767px)" type="image/webp" srcset="${nuxtApp.imgProxyResize(src, additional?.desktopImageWidth, undefined, 'webp')} 1x, ${nuxtApp.imgProxyResize(src, Math.round(additional?.desktopImageWidth * 2), undefined, 'webp')} 2x">
<source media="(min-width: 767px)" srcset="${nuxtApp.imgProxyResize(src, additional?.desktopImageWidth)} 1x, ${nuxtApp.imgProxyResize(src, Math.round(additional?.desktopImageWidth * 2))} 2x">
<img loading="lazy" src="${nuxtApp.imgProxyResize(src, Math.round(additional?.desktopImageWidth / 2))}" ${text ? 'alt="' + text + '"' : ''} ${title ? 'title="' + title + '"' : ''} width="624" height="624" class="rounded-lg bg-soft-white-100 w-full" />
</picture></a>`;
                    } else if (isArticle) {
                        return `<a href="${nuxtApp.imgProxyResize(src, 700)}" data-fancybox><picture>
<source media="(max-width: 766px)" type="image/webp" srcset="${nuxtApp.imgProxyResize(src, 350, undefined, 'webp')} 1x, ${nuxtApp.imgProxyResize(src, 700, undefined, 'webp')} 2x">
<source media="(max-width: 766px)" srcset="${nuxtApp.imgProxyResize(src, 350)} 1x, ${nuxtApp.imgProxyResize(src, 700)} 2x">
<source media="(min-width: 767px)" type="image/webp" srcset="${nuxtApp.imgProxyResize(src, 624, undefined, 'webp')} 1x, ${nuxtApp.imgProxyResize(src, 1248, undefined, 'webp')} 2x">
<source media="(min-width: 767px)" srcset="${nuxtApp.imgProxyResize(src, 624)} 1x, ${nuxtApp.imgProxyResize(src, 1248)} 2x">
<img loading="lazy" src="${nuxtApp.imgProxyResize(src, 350)}" ${text ? 'alt="' + text + '"' : ''} ${title ? 'title="' + title + '"' : ''} width="624" height="624" class="rounded-lg bg-soft-white-100 w-full" />
</picture></a>`;
                    } else {
                        return `<a href="${nuxtApp.imgProxyResize(src, 700)}" data-fancybox><picture>
<source media="(max-width: 766px)" type="image/webp" srcset="${nuxtApp.imgProxyResize(src, 250, 250, 'webp')} 1x, ${nuxtApp.imgProxyResize(src, 500, 500, 'webp')} 2x">
<source media="(max-width: 766px)" srcset="${nuxtApp.imgProxyResize(src, 250)} 1x, ${nuxtApp.imgProxyResize(src, 700)} 2x">
<source media="(min-width: 767px)" type="image/webp" srcset="${nuxtApp.imgProxyResize(src, 500, 500, 'webp')} 1x, ${nuxtApp.imgProxyResize(src, 1000, 1000, 'webp')} 2x">
<source media="(min-width: 767px)" srcset="${nuxtApp.imgProxyResize(src, 500, 500)} 1x, ${nuxtApp.imgProxyResize(src, 1000, 1000)} 2x">
<img loading="lazy" src="${nuxtApp.imgProxyResize(src, 250, 250)}" ${text ? 'alt="' + text + '"' : ''} ${title ? 'title="' + title + '"' : ''} width="500" height="500" class="rounded-lg bg-soft-white-100" />
</picture></a>`;
                    }
                }
            }
        });
        return instance;
    }
});