import { markRaw } from "vue";
import { useNuxtApp } from "#app";
import { useRoute } from "vue-router";

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.$pinia.use(() => ({
        $useNuxtApp: markRaw(useNuxtApp()),
        $useRuntimeConfig: useRuntimeConfig(),
        $useRoute: markRaw(useRoute()),
    }));
});