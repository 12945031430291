import pb, { GravityType, ResizeType, WatermarkPosition } from '@bitpatty/imgproxy-url-builder';
import { FormatOptions } from "@bitpatty/imgproxy-url-builder/dist/types/transformers/format";

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    nuxtApp.imgProxyResize = (
        originalUrl: string,
        width?: number,
        height?: number,
        extension?: FormatOptions,
        type: ResizeType = ResizeType.AUTO,
    ): string => {
        const image = pb()
            .resize({
                type,
                width,
                height,
            })
            .enlarge();

        if (extension) {
            image.format(extension);
        }

        return image.build({
            path: originalUrl,
            baseUrl: config.public.cdnMediaBaseUrl,
            signature: {
                key: config.public.imgproxyKey,
                salt: config.public.imgproxySalt,
                size: 32,
            },
        })
    };
});