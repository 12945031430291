import { default as index1JQZ1qn7X7Meta } from "/opt/app/pages/index.vue?macro=true";
import { default as index62Q7x4HSwHMeta } from "/opt/app/pages/ingredients/index.vue?macro=true";
import { default as category_45_91category_93sbjONvmyixMeta } from "/opt/app/pages/interesting-facts/category-[category].vue?macro=true";
import { default as fact_45_91slug_93USpCz0T6vtMeta } from "/opt/app/pages/interesting-facts/fact-[slug].vue?macro=true";
import { default as indexHWB6VVwZO1Meta } from "/opt/app/pages/interesting-facts/index.vue?macro=true";
import { default as category_45_91category_93LtJJLKzbjdMeta } from "/opt/app/pages/recipes/category-[category].vue?macro=true";
import { default as indexAnbL8YK5nAMeta } from "/opt/app/pages/recipes/index.vue?macro=true";
import { default as recipe_45_91slug_93h2wf8hdrYGMeta } from "/opt/app/pages/recipes/recipe-[slug].vue?macro=true";
import { default as category_45_91category_9334XBMgtSEzMeta } from "/opt/app/pages/reviews/category-[category].vue?macro=true";
import { default as indexJMm1fqdhLaMeta } from "/opt/app/pages/reviews/index.vue?macro=true";
import { default as review_45_91slug_93KpCO8QUDSrMeta } from "/opt/app/pages/reviews/review-[slug].vue?macro=true";
import { default as category_45_91category_939wYPcWvQLQMeta } from "/opt/app/pages/shop/category-[category].vue?macro=true";
import { default as indexKPVfdoQLhBMeta } from "/opt/app/pages/shop/index.vue?macro=true";
import { default as product_45_91slug_93poSkVYjdOuMeta } from "/opt/app/pages/shop/product-[slug].vue?macro=true";
import { default as indexilnGXe1e6HMeta } from "/opt/app/pages/tips/index.vue?macro=true";
export default [
  {
    name: index1JQZ1qn7X7Meta?.name ?? "index",
    path: index1JQZ1qn7X7Meta?.path ?? "/",
    meta: index1JQZ1qn7X7Meta || {},
    alias: index1JQZ1qn7X7Meta?.alias || [],
    redirect: index1JQZ1qn7X7Meta?.redirect || undefined,
    component: () => import("/opt/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: index62Q7x4HSwHMeta?.name ?? "ingredients",
    path: index62Q7x4HSwHMeta?.path ?? "/ingredients",
    meta: index62Q7x4HSwHMeta || {},
    alias: index62Q7x4HSwHMeta?.alias || [],
    redirect: index62Q7x4HSwHMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/ingredients/index.vue").then(m => m.default || m)
  },
  {
    name: category_45_91category_93sbjONvmyixMeta?.name ?? "interesting-facts-category-category",
    path: category_45_91category_93sbjONvmyixMeta?.path ?? "/interesting-facts/category-:category()",
    meta: category_45_91category_93sbjONvmyixMeta || {},
    alias: category_45_91category_93sbjONvmyixMeta?.alias || [],
    redirect: category_45_91category_93sbjONvmyixMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/interesting-facts/category-[category].vue").then(m => m.default || m)
  },
  {
    name: fact_45_91slug_93USpCz0T6vtMeta?.name ?? "interesting-facts-fact-slug",
    path: fact_45_91slug_93USpCz0T6vtMeta?.path ?? "/interesting-facts/fact-:slug()",
    meta: fact_45_91slug_93USpCz0T6vtMeta || {},
    alias: fact_45_91slug_93USpCz0T6vtMeta?.alias || [],
    redirect: fact_45_91slug_93USpCz0T6vtMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/interesting-facts/fact-[slug].vue").then(m => m.default || m)
  },
  {
    name: indexHWB6VVwZO1Meta?.name ?? "interesting-facts",
    path: indexHWB6VVwZO1Meta?.path ?? "/interesting-facts",
    meta: indexHWB6VVwZO1Meta || {},
    alias: indexHWB6VVwZO1Meta?.alias || [],
    redirect: indexHWB6VVwZO1Meta?.redirect || undefined,
    component: () => import("/opt/app/pages/interesting-facts/index.vue").then(m => m.default || m)
  },
  {
    name: category_45_91category_93LtJJLKzbjdMeta?.name ?? "recipes-category-category",
    path: category_45_91category_93LtJJLKzbjdMeta?.path ?? "/recipes/category-:category()",
    meta: category_45_91category_93LtJJLKzbjdMeta || {},
    alias: category_45_91category_93LtJJLKzbjdMeta?.alias || [],
    redirect: category_45_91category_93LtJJLKzbjdMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/recipes/category-[category].vue").then(m => m.default || m)
  },
  {
    name: indexAnbL8YK5nAMeta?.name ?? "recipes",
    path: indexAnbL8YK5nAMeta?.path ?? "/recipes",
    meta: indexAnbL8YK5nAMeta || {},
    alias: indexAnbL8YK5nAMeta?.alias || [],
    redirect: indexAnbL8YK5nAMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/recipes/index.vue").then(m => m.default || m)
  },
  {
    name: recipe_45_91slug_93h2wf8hdrYGMeta?.name ?? "recipes-recipe-slug",
    path: recipe_45_91slug_93h2wf8hdrYGMeta?.path ?? "/recipes/recipe-:slug()",
    meta: recipe_45_91slug_93h2wf8hdrYGMeta || {},
    alias: recipe_45_91slug_93h2wf8hdrYGMeta?.alias || [],
    redirect: recipe_45_91slug_93h2wf8hdrYGMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/recipes/recipe-[slug].vue").then(m => m.default || m)
  },
  {
    name: category_45_91category_9334XBMgtSEzMeta?.name ?? "reviews-category-category",
    path: category_45_91category_9334XBMgtSEzMeta?.path ?? "/reviews/category-:category()",
    meta: category_45_91category_9334XBMgtSEzMeta || {},
    alias: category_45_91category_9334XBMgtSEzMeta?.alias || [],
    redirect: category_45_91category_9334XBMgtSEzMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/reviews/category-[category].vue").then(m => m.default || m)
  },
  {
    name: indexJMm1fqdhLaMeta?.name ?? "reviews",
    path: indexJMm1fqdhLaMeta?.path ?? "/reviews",
    meta: indexJMm1fqdhLaMeta || {},
    alias: indexJMm1fqdhLaMeta?.alias || [],
    redirect: indexJMm1fqdhLaMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/reviews/index.vue").then(m => m.default || m)
  },
  {
    name: review_45_91slug_93KpCO8QUDSrMeta?.name ?? "reviews-review-slug",
    path: review_45_91slug_93KpCO8QUDSrMeta?.path ?? "/reviews/review-:slug()",
    meta: review_45_91slug_93KpCO8QUDSrMeta || {},
    alias: review_45_91slug_93KpCO8QUDSrMeta?.alias || [],
    redirect: review_45_91slug_93KpCO8QUDSrMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/reviews/review-[slug].vue").then(m => m.default || m)
  },
  {
    name: category_45_91category_939wYPcWvQLQMeta?.name ?? "shop-category-category",
    path: category_45_91category_939wYPcWvQLQMeta?.path ?? "/shop/category-:category()",
    meta: category_45_91category_939wYPcWvQLQMeta || {},
    alias: category_45_91category_939wYPcWvQLQMeta?.alias || [],
    redirect: category_45_91category_939wYPcWvQLQMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/shop/category-[category].vue").then(m => m.default || m)
  },
  {
    name: indexKPVfdoQLhBMeta?.name ?? "shop",
    path: indexKPVfdoQLhBMeta?.path ?? "/shop",
    meta: indexKPVfdoQLhBMeta || {},
    alias: indexKPVfdoQLhBMeta?.alias || [],
    redirect: indexKPVfdoQLhBMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/shop/index.vue").then(m => m.default || m)
  },
  {
    name: product_45_91slug_93poSkVYjdOuMeta?.name ?? "shop-product-slug",
    path: product_45_91slug_93poSkVYjdOuMeta?.path ?? "/shop/product-:slug()",
    meta: product_45_91slug_93poSkVYjdOuMeta || {},
    alias: product_45_91slug_93poSkVYjdOuMeta?.alias || [],
    redirect: product_45_91slug_93poSkVYjdOuMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/shop/product-[slug].vue").then(m => m.default || m)
  },
  {
    name: indexilnGXe1e6HMeta?.name ?? "tips",
    path: indexilnGXe1e6HMeta?.path ?? "/tips",
    meta: indexilnGXe1e6HMeta || {},
    alias: indexilnGXe1e6HMeta?.alias || [],
    redirect: indexilnGXe1e6HMeta?.redirect || undefined,
    component: () => import("/opt/app/pages/tips/index.vue").then(m => m.default || m)
  }
]