import axios from "axios";

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig();
    const baseURL = process.server ? config.public.ssrApiBaseUrl : config.public.apiBaseUrl;
    const axiosServices = axios.create({
        baseURL,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${config.public.apiToken}`,
        },
        timeout: 10000,
        withCredentials: false,
    });

    axiosServices.interceptors.response.use(
        (response) => response,
        (error) => {
            if (error.response?.data?.statusCode === 401) {
                navigateTo('/');
            }

            console.log(error);

            return Promise.reject((error.response && error.response.data) || "Wrong Services");
        }
    );

    nuxtApp.axios = axiosServices;
});